<template>
  安全设置
</template>

<script>
export default {
  name: "secure-manage",
}
</script>

<style>

</style>